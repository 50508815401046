import { STRIPE_CUSTOM, CUSTOMER, STRIPE_STANDARD } from "@/store/organizations/types";

export async function saveGuestToken (to, from, next) {
  const query = to.query;
  const isGuest = !localStorage.getItem('auth_token_default');
  if(!isGuest){
    return next();
  }
  if (!query.token && !query.type){
    return next({ name: 'login' });
  }
  if(isGuest){
    localStorage.setItem('token', query.token);
    localStorage.setItem('token_type', query.type);
    sessionStorage.setItem('redirect_to', to.path);
    return next({ name: 'login' });
  }
  return next();
}

export const EXCLUSIVE_PATHS = {  // key: access_level, value: paths, which are available only for that level
  [STRIPE_CUSTOM]: [
    "/payouts/create",
  ],
  [STRIPE_STANDARD]: [
    "/stripe-payouts",
  ]
}

export const FORBIDDEN_PATHS = {  // key: access_level, value: paths, which are unavailable only for that level
  [STRIPE_STANDARD]: [
    "/reports",
    "/payouts-methods",
    // "/payouts-requests",  // is available while we move inetnums from custom to standard accounts
  ],
  [CUSTOMER]: [
    "/payouts-requests",
    "/payouts-methods",
  ]
}

export const ALTERED_PATHS = {  // key: access_level, value: dict. key: path, value: new path (works only for that level)
  [STRIPE_STANDARD]: {
    "/payouts-requests": "/stripe-payouts"
  }
}
