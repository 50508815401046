<template>
  <div class="header-navigation">
    <app-dropdown>
      <template #default="{ isOpened }">
        <div class="header-navigation__trigger">
          <app-tooltip :offset="30">
            <user-avatar :size="36" bordered :is-active="isOpened" />
            <template #content>
              {{ $t("Your profile and settings") }}
            </template>
          </app-tooltip>
        </div>
      </template>
      <template #content>
        <div class="header-navigation__content">
          <div class="user-info">
            <div class="user-info__avatar">
              <user-avatar :size="44" />
            </div>
            <div v-if="user.name" class="user-info__name">
              {{ user.name }}
            </div>
            <div v-if="user.email" class="user-info__email">
              {{ user.email }}
            </div>
          </div>
          <nav class="subnavigation">
            <div class="subnavigation__delimiter" />
            <ul>
              <header-navigation-item
                to="/settings"
                icon="perm_identity"
                :label="$t('My account')"
              />
              <header-navigation-item
                to="/organization"
                icon="dns"
                :label="$t('Business accounts')"
              />
              <div class="subnavigation__delimiter" />
              <header-navigation-item icon="login" :label="$t('Sign out')" @click="logout" />
            </ul>
          </nav>
        </div>
      </template>
    </app-dropdown>
  </div>
</template>

<script>
import AppDropdown from "@/components/ui/Dropdown"
import AppTooltip from "@/components/ui/Tooltip"
import UserAvatar from "@/components/ui/CurrentUserAvatar"
import HeaderNavigationItem from "./HeaderNavigationItem"

export default {
  components: { AppTooltip, UserAvatar, AppDropdown, HeaderNavigationItem },
  computed: {
    user() {
      return this.$auth.user() || {}
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("alerts/clearAlerts", { type: "main" })
      this.$store.dispatch("wannahost/logout")
      this.$auth.logout({
        makeRequest: false,
        redirect: { name: "login" },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.header-navigation {
  position: relative;
  &__content {
    padding: 5px 0 8px 0;
    width: 224px;
  }
}
.subnavigation {
  &__delimiter {
    height: 1px;
    background: rgba(217, 223, 246, 0.5);
    margin-bottom: 3px;
    margin-top: 14px;
  }
  ul {
    margin-top: 10px;
  }
}
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__avatar {
    margin-bottom: 12px;
  }
  &__name {
    font-family: var(--font-secondary);
    color: var(--text-color);
    margin-bottom: 4px;
    font-weight: 500;
  }
  &__email {
    color: var(--text-grey);
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding: 0 12px;
  }
}
</style>
