import Vue from "vue"
import {
  ORGANIZATIONS,
  ORGANIZATION,
  GET_ORGANIZATIONS,
  SET_ORGANIZATIONS,
  SET_ORGANIZATION,
  ORGANIZATION_ID,
  ACCESS_LEVEL,
  NO_ORGANIZATION,
} from "./types"

const actions = {
  async [GET_ORGANIZATIONS]({ commit, state }) {
    const { data: organizations } = await Vue.axios.get("/organizations/")
    commit(SET_ORGANIZATIONS, organizations)
    const savedOrganizationId = localStorage.getItem(ORGANIZATION_ID)
    const hasSavedOrganizationInList = !!organizations.find(
      (org) => org.unique_id === savedOrganizationId
    )
    const firstOrganizationId = organizations[0] ? organizations[0].unique_id : null
    const selectedOrganizationId = hasSavedOrganizationInList
      ? savedOrganizationId
      : firstOrganizationId
    const accessLevel = actions.getAccessLevel(selectedOrganizationId)
    commit(SET_ORGANIZATION, selectedOrganizationId, accessLevel)
    localStorage.setItem(ORGANIZATION_ID, selectedOrganizationId)
    if (!localStorage.getItem(ACCESS_LEVEL) || localStorage.getItem(ACCESS_LEVEL) !== accessLevel) {
      localStorage.setItem(ACCESS_LEVEL, accessLevel)
      location.reload()
    }
    return organizations
  },
  getAccessLevel(organizationId) {
    if (!organizationId) {
      return NO_ORGANIZATION
    }
    const level = state[ORGANIZATIONS].find((org) => org.unique_id === organizationId).access_level
    return level ? level : NO_ORGANIZATION
  },
  async [SET_ORGANIZATION]({ commit }, organizationId) {
    if (!organizationId) {
      return
    }
    const accessLevel = actions.getAccessLevel(organizationId)
    commit(SET_ORGANIZATION, organizationId, accessLevel)
    localStorage.setItem(ORGANIZATION_ID, organizationId)
    if (localStorage.getItem(ACCESS_LEVEL) !== accessLevel) {
      localStorage.setItem(ACCESS_LEVEL, accessLevel)
      location.reload()
    }
  },
  async getOrganization(app, uid) {
    return await Vue.axios.get(`/organizations/${uid}`)
  },
}
const mutations = {
  [SET_ORGANIZATIONS](state, organizations) {
    state[ORGANIZATIONS] = organizations
  },
  [SET_ORGANIZATION](state, organizationId, accessLevel) {
    state[ORGANIZATION_ID] = organizationId
    state[ACCESS_LEVEL] = accessLevel
  },
}
const state = {
  [ORGANIZATIONS]: null,
  [ORGANIZATION_ID]: null,
  [ACCESS_LEVEL]: null,
}
const getters = {
  [ORGANIZATION](state) {
    if (!state[ORGANIZATION_ID]) {
      return null
    }
    return state[ORGANIZATIONS].find((org) => org.unique_id === state[ORGANIZATION_ID])
  },
}
export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
}
